#primary-nav .level-1 {
  width: 16.6666%;

}

#drawer-nav .level-2 {
  padding: 0 5px;
}

.brand__lockup {
  img {
    height: 95px;
  }
}

.btn--wcm {
  height: 42px;
  line-height: 31px;
}

.section-inner {
  max-width: 1200px;
}

footer {
  padding-top: 0;
}

#banner {
  background: url('../images/background.jpg') right top / cover no-repeat;

  @include breakpoint($sm) {
    background: url('../images/background.jpg') center top / cover no-repeat;
  }

  width: 100%;

  .pane-bundle-text {
    .field-item {
      overflow: hidden;
    }

    .fieldable-panels-pane {
      padding: 95px 0 15px 20px;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint($sm) {
        margin-left: 345px;
      }

      @include breakpoint($md) {
        margin-left: 440px;
        width: 515px;
      }

      @include breakpoint($lg) {
        margin-left: 620px;
      }
    }

    p {

      display: block;
      color: $wcm-dark-gray;
      margin: 0;
      font-size: 20px;
      line-height: 1.666;
      float: none;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint($sm) {
        width: auto;
        max-width: 505px;
        clear: left;
        margin-left: 0;
      }

      padding: 0 5px;
    }

    .btn--wcm {
      margin: 20px 20px 0 0;
      display: inline-block;
      background-color: transparent;
      color: $wcm-bright-orange;

      &:hover {
        color: $wcm-dark-gray;
      }

      &:before {
        color: $wcm-dark-gray;
      }
    }

    p.nomar {
      max-width: none;

      @include breakpoint($md) {
        margin: 0;
      }
    }

    .wcmc-button {
      &:first-child {
        margin-top: 34px;
        margin-right: 34px;
      }

      margin-bottom: 20px;
      margin-top: 34px;

      @include breakpoint($sm) {
        margin-bottom: 20px;
      }
    }
  }
}

#last {
  width: 100%;
  min-height: 145px;
  background-color: $wcm-bright-orange;

  p {
    color: #ffffff;
    line-height: 27px;
    font-size: 18px;
    padding-top: 30px;
    margin-bottom: 0px;

    a {
      width: 100%;
      display: block;
      padding: 7px 0 0;

      @include breakpoint($sm) {
        display: inline;
        width: auto;
        padding: 15px 0 0 0;
        margin: 0 22px;
      }

      color: #fff;
      font-size: 14px;
      text-decoration: underline;

      &:first-child {
        padding-right: 15px;
      }
    }
  }
}

#donnews {
  .panel-separator {
    float: left;
    width: 0;
    display: none;
  }

  .pane-fieldable-panels-pane {
    margin: 10px;

    @include breakpoint($md) {
      margin: 58px 0 80px;
    }

    float: left;

    @include breakpoint($md) {
      width: 100%;
    }

    min-height: 250px;

    &:first-child {
      padding-right: 25px;
      box-sizing: border-box;

      @include breakpoint($md) {
        width: 64.1%;
      }

      @include breakpoint($md) {
        border-right: 1px solid #A8ADB3;
      }
    }

    @include breakpoint($md) {
      width: 35.65%;
    }

    .wcmc-button {
      color: #807F80;
      border-color: #807F80;

      &:hover {
        background-color: #807F80;
        color: #fff;
      }
    }
  }

  p {
    line-height: 27px;
  }


  img {
    float: left;
    margin-right: 10px;
    width: 49.5%;

    @include breakpoint($md) {
      margin-right: 33px;
    }
  }

  .pane-uuid-879229f0-7b39-40c6-951e-909b8fdd6662 {
    p {

      &:first-child {
        margin-left: 0px;
      }
    }
  }

  .pane-uuid-4c5bde6b-4f20-4e59-8f05-4fddc3572b68 {
    h3 {
      @include breakpoint($md) {
        margin-left: 33px;
        margin-bottom: 12px;

      }
    }
  }
}

#app {
  background-color: $wcm-dark-orange;
  width: 100%;
  min-height: 250px;
  display: block;

  .section-inner {
    margin: 0 auto;
    display: block;
    max-width: 100%;
  }

  .fieldable-panels-pane {
    @include breakpoint($md) {
      float: right;
      clear: left;
      width: 100%;
      overflow: hidden;
    }
  }

  h3.pane-title a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      display: none;
    }
  }

  //.pane-uuid-7aaeea4a-56e1-4bdc-96cd-c6bdbea703d4 div.fieldable-panels-pane{
  .pane-vuuid-0d786f6e-4b9f-4d85-95b8-39c09716794c div.fieldable-panels-pane {
    @include breakpoint($sm) {
      float: right;
      width: 600px;
      clear: both;
    }
  }

  .panel-pane {
    width: 100%;

    @include breakpoint($sm) {
      width: 50%;
    }

    float: left;

    .pane-content {
      max-width: 600px;
      float: none;
      display: block;
      clear: both;
      margin: 0 auto;

      @include breakpoint($md) {
        float: right;
        clear: left;
        width: 600px;
      }
    }

    h3 {
      font-size: 30px;
      font-family: "Open-sans", sans-serif;
      text-transform: none;
      color: #fff;
      max-width: 600px;
      float: none;
      display: block;
      clear: both;
      margin: 16px auto;

      @include breakpoint($md) {
        float: right;
        clear: left;
        margin: 16px 0;
        width: 600px;
      }

      text-align: center;
      font-weight: normal;
    }

    p {
      text-align: center;
      color: #fff;

      @include breakpoint($md) {
        float: right;
        clear: left;
        width: 600px;
      }
    }

    a.tel {
      font-size: 30px;
      font-family: "Open-sans", sans-serif;
      text-transform: none;
      color: #fff;
      max-width: 600px;
      float: none;
      display: block;
      clear: both;
      margin: 0 auto;
    }

    a.btn--wcm {
      background-color: transparent;
      color: $wcm-bright-orange;

      &:hover {
        border-color: $wcm-bright-orange;
        color: #fff;

      }
    }
  }



  .pane-vuuid-462342ef-4919-4042-941a-72483927363b {
    overflow: hidden;
    width: 100%;

    @include breakpoint($sm) {
      width: 50%;
    }

    .pane-content {
      height: 250px;
      width: 100%;
      max-width: none;
      float: left;

      .fieldable-panels-pane {
        height: 100%;

        .field {
          height: 100%;

          .field-items {
            height: 100%;

            .field-item {
              height: 100%;
            }
          }
        }
      }
    }

    p {
      width: 100%;
      height: 100%;
      margin: 0px;

      a {
        width: 100%;
        min-height: 250px;
        font-size: 0px;
        text-indent: -9999px;
        display: block;
        background: url(../images/homemap.jpg) center center no-repeat;
        background-size: cover;
        //-webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        transition: all 1s;

        &:hover {
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
          transform: scale(1.08, 1.08);
        }
      }
    }
  }
}

#ctas {
  h3.pane-title a {
    color: #fff;

    &:hover {
      text-decoration: underline;
    }

    &:after {
      display: none;
    }
  }

  .pane-bundle-text {
    &:hover {
      background-color: #e87722;

      .arrow-right {
        border-left: 17px solid #e87722;
        top: -63px;
      }

      a.btn {
        display: inline-block;
        background-color: $wcm-dark-orange;
      }
    }

    .arrow-right {
      display: none;

      @include breakpoint($md) {
        display: block;
        position: relative;
        left: 320px;
        top: -62px;
        width: 0;
        height: 0;
        border-top: 17px solid transparent;
        border-bottom: 17px solid transparent;
      }
    }
  }

  .panel-pane {
    width: 96%;
    margin: 5px 2% 5px;
    float: left;
    background-color: #999999;
    height: 144px;
    box-sizing: border-box;

    @include breakpoint($sm) {
      margin: 25px 1px 38px;
      width: 32.9%;
    }

    @include breakpoint($md) {
      margin: 25px 2px 23px;

    }

    h3 {
      font-size: 27px;
      font-family: "Open-sans", sans-serif;
      text-transform: none;
      color: #fff;
      text-align: center;
      font-weight: normal;
      margin: 13px 0 7px;
    }

    p {
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      margin: 0;
    }

    a.btn {
      float: none;
      margin-right: 0px;
      background-color: transparent;
      color: #fff;
      margin-top: 20px;
      border: none;

      &:hover {
        background-color: $wcm-dark-orange;
      }
    }
  }

  .pane-uuid-c81baede-f54f-4b64-86be-9c56631092ec {
    background-color: #666;

    .arrow-right {
      @include breakpoint($md) {
        border-left: 17px solid #666;
      }

      @include breakpoint($lg) {
        left: 394px;

      }
    }
  }

  .pane-uuid-2fc81575-0747-4a09-be39-fd115e48e325 {
    background-color: #999999;

    .arrow-right {
      @include breakpoint($md) {
        border-left: 17px solid #999999;
      }

      @include breakpoint($lg) {
        left: 395px;

      }
    }
  }

  .pane-uuid-bf6d82bc-48ac-46d6-98f4-4149725d47b3 {
    background-color: #B2B2B2;

    .arrow-right {
      @include breakpoint($md) {
        border-left: 17px solid #B2B2B2;

      }

      @include breakpoint($lg) {
        left: 394px;
      }
    }
  }
}

/* Bulk import */

.contact-info {
  p {
    line-height: 22px;
  }

  a.map {
    &:before {
      content: '\e803';
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      speak: none;
      text-decoration: inherit;
      text-align: center;
      font-variant: normal;
      text-transform: none;
      line-height: 1em;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      padding-right: 5px;
    }
  }
}

.spiftv-links {
  padding-left: 0px;
  list-style: none;
  border-bottom: 1px solid #EFEFEF;
  margin-top: 0px;

  li {
    width: 100%;
    float: left;

    a {
      float: left;
      display: inline-block;
      font-size: 16px;
      margin: 0 0 25px 0;
      height: 34px;
      line-height: 34px;
      position: relative;
      padding: 0 10px 0 5px;
      width: 128px;

      &:hover {
        background-color: #3787b0;
        color: #ffffff;
        text-decoration: none;
        ;
      }
    }

    a.wcmc-social-button {
      background-color: #3787b0;
      border-radius: 0px;
      width: 34px;
      font-size: 20px;
      box-sizing: border-box;
      padding-top: 3px;
      float: left;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.item-list ul {
  padding: 0 !important;
}
.node-type-profile{
  .panel-hero-3col{
    #related-content-sidebar{
      display: block;
      @include breakpoint($sm){
       width: 420px;
        margin-left: 30px;
      }

      @include breakpoint($lg){
        width: 615px;
      }
      .field-label{
        float: none;
        display: inline;
        font-weight: bold;
      }
      .field-items{
        float: none;
        display: inline;
        .field-item{
          float: none;
          display: inline;
        }
      }
    }
  }
}


.panel-hero-3col {
  #related-content-sidebar {
    width: 200px;
    margin-left: 27px;
    border-left: 1px solid #C7CBCE;
    padding-left: 1em;

    .pane-title {
      text-transform: capitalize;
      letter-spacing: unset;
      font-size: 16px;
    }

  }

}


.field-name-field-pops-profile-link {
  width: 100%;
  float: left;

  a {
    float: left;
    padding: 0 13px;
    background-color: transparent;
    color: #807F80;
    border-radius: 0;
    border: 2px solid #807F80;
    text-transform: uppercase;
    width: auto;
    font-weight: normal;
    height: 33px;
    line-height: 33px;
    margin-top: 3px;
    margin-bottom: 32px;
    font-size: 13px;

    &:hover {
      color: #ffffff;
      background-color: #807F80;
    }

    &:after {
      font-family: "SSStandard";
      content: '↪';
      font-size: 75%;
      vertical-align: top;
      text-rendering: optimizeLegibility;
      display: inline-block;
      text-decoration: none;
      -ms-font-feature-settings: "liga"1;
      padding-left: 5px;
    }
  }
}

.pane-node-field-biographical-info {
  float: left;
}

.views-field-field-news-category,
.field-name-field-news-category {
  a {
    font-size: 14px;
    background: url(../images/tag.png) 5px 0 no-repeat;
    background-size: 16px 16px;
    padding-left: 30px;
  }

  .views-label,
  .field-label {
    font-weight: bold;
    float: left;
  }

}

label[for=edit-sort-by] {
  display: none;
}

.view-teaser {
  border-bottom: none;
}

#edit-sort-by {
  border-radius: 0;
  border: 1px solid #DBDCDD;
  font-size: 13px;
}

figure.hero-image {
  margin-bottom: 4em;
}

.sp_cta {
  .imgholder {
    overflow: hidden;
    float: left;
    height: auto;
    margin: 0 20px 19px 0;

    @include breakpoint($sm) {
      width: 45%;
    }
  }

  padding: 30px 0;
}

.panel-hero-3col #main-content {
  padding-bottom: 25px;
}

.view-display-id-panel_pane_1 {
  .views-row-even {
    background-color: #F3F3F3;
  }

  h3 {
    color: #000;
    font-weight: bold;
    text-transform: capitalize;
    font-family: "Open-sans", sans-serif;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 25px;
  }

  .views-field-biblio-date {
    font-size: 12px;
    padding-left: 19px;
    padding-right: 19px;
  }

  .views-field-biblio-authors {
    font-size: 12px;
    padding: 7px 19px;
  }

  .views-field-biblio-abst-e {
    padding-left: 19px;
    padding-right: 19px;
    padding-bottom: 25px;
  }
}


.pane-profiles-panel-pane-3,
.pane-profiles-panel-pane-6 {
  width: 100%;
  float: left;

  .aboutprof {
    width: 50%;
    float: left;
    margin: 25px 0;
    max-width: 240px;

    @include breakpoint($md) {
      max-width: 100%;
    }
  }

  img {
    width: 95%;
    float: left;

    @include breakpoint ($sm) {
      width: 32%;

    }
  }

  h4,
  p,
  a {
    width: 100%;
    float: left;

    @include breakpoint ($sm) {
      width: 68%;
    }
  }

  a {
    width: 100%;
    font-size: 14px;

    @include breakpoint($sm) {
      width: auto;
      padding-left: 19px;
    }

    float: left;
    margin: 0;
  }

  a.seeall {
    text-transform: uppercase;
    width: 100%;
    padding-left: 0;
  }

  h4 {

    @include breakpoint($sm) {
      margin-top: 0px;
    }

    float: left;
    margin: 10px 0 0 0;

    a {
      font-size: 18px;
      max-width: 225px;

    }
  }

  p {
    font-size: 14px;
    font-family: "Open-sans", sans-serif;
    font-style: italic;
    float: left;
    margin: 0;

    @include breakpoint($sm) {
      padding-left: 19px;
    }
  }
}

.pane-news-panel-pane-6 {
  h4 {
    margin: 0;
  }

  img {
    float: left;
    width: 320px;
    height: auto;
    border-radius: 0;
    padding-right: 19px;
  }

  p.post-date {
    margin-bottom: 0px;
    margin-top: 0px;
  }

  a.seeall {
    padding-bottom: 20px;
    float: left;
    width: 100%;
    text-transform: uppercase;
  }
}

.pane-node-body {
  img {
    padding-right: 30px;
  }

  h2,
  h3 {
    margin-bottom: 15px;
  }
}


//Related content pane
.pane-node-field-related-articles,
.pane-publications-papers-panel-pane-1,
.pane-publications-papers-panel-pane-2 {
  h3 {
    font-size: 16px;
    color: #4B525A;
    margin-left: 20px;
  }
}

#contentsb {
  width: 200px;
  margin-left: 27px;
  border-left: 1px solid #C7CBCE;
  float: right;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-left: 20px;

      &:before {
        content: "• ";
        color: #C9CACC;
        font-size: 16px;
      }

      div {
        display: inline;

        span {
          display: inline;
        }
      }
    }
  }
}

.speechprof {
  float: left;
  margin: 25px 0;

  @include breakpoint($sm) {
    width: 50%;
  }

  width: 100%;

  h4 {
    margin: 0 0 10px;
  }

  img {
    float: left;
    margin-right: 19px;
    border: 1px solid #cccccc;
  }

  span.summ {
    float: left;
    margin-top: 12.5px;
    margin-right: 10px;
  }
}

.pane-profiles-panel-pane-5 {
  margin-top: 30px;
  float: left;
  width: 100%;
}

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
}

/* Over writing button styles */

.btn--wcm {
  border-radius: 15px;
}

.btn {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 10px;
}

.speechprof a.external-link,
.pane-node-field-pops-profile-link a.external-link {
  font-size: 14px;
  background: url('../images/sprites/clinprof.svg') 0 0 no-repeat;
  background-size: 32px 32px;
  padding-left: 42px;
  min-height: 32px;
  line-height: 16px;

  @include breakpoint($sm) {
    line-height: 32px;
  }

  display: block;

  &:after {
    display: none;
  }
}

.speechprof a.external-link {
  float: left;
}

a.external-link {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

span.select2 {
  min-width: 200px;
}

.view-id-News.view-display-id-panel_pane_5 {
  .views-field-body {
    display: inline;

    .field-content {
      display: inline;

      p {
        display: inline;
      }
    }
  }

  .views-field-view-node {
    display: inline;
  }

  .views-field-field-news-category {
    margin-top: 10px;
  }
}

.view-display-id-panel_pane_5 {
  .view-teaser {
    margin-bottom: 20px;
  }
}

.pane-views-exp-news-panel-pane-5 {
  .form-item-sort-by {
    display: none;
  }

  label[for='edit-sort-order'] {
    display: none;
  }

  .views-widget-sort-order {
    float: none;
  }
}

.view-display-id-panel_pane_5 {
  .field-content {
    p:not(:first-child) {
      display: none;
    }

    a.moreless {
      padding-bottom: 5px;
    }
  }
}

.pane-1 {
  a.btn {
    margin-top: 10px;
  }

  h3 {
    float: left;
    width: 100%;
  }
}

.btn-don {
  margin: 10px 10px 0 0;
  height: 86px;
  display: table-cell;
  line-height: 74px;
  float: left;
  width: 100%;

  @include breakpoint($sm) {
    width: 50%;
    margin-bottom: 40px;
  }

  margin-bottom: 20px;
  color: $wcm-dark-orange;
  border-color: $wcm-dark-orange;
  font-size: 15px;
  text-align: right;

  span {
    background-image: url("../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg");
    background-position: -480px 0px;
    background-repeat: no-repeat;
    color: rgb(85, 85, 85);
    display: inline-block;
    font-size: 64px;
    height: 64px;
    margin: 5px 0 5px 55px;
    width: 64px;
    vertical-align: middle;

    @include breakpoint($sm) {
      margin-left: 70px;
    }
  }

  &:hover {
    background-color: #fff;
    text-decoration: underline;
    color: $wcm-bright-orange;
    border-color: $wcm-bright-orange;
  }

  &:before {
    display: none;
  }
}

.btn-cont {
  float: left;
  width: 100%;
  margin: 0 0 10px 0;

  @include breakpoint($sm) {
    width: 48%;
    margin: 10px 0 0 0;
  }

  height: 37px;
  line-height: 37px;
}

.views-field-field-publication-author {
  width: 100%;
  float: left;
}

.views-label,
.field-label {
  font-weight: bold;
  float: left;
  padding-right: 5px;
}

.pane-node-field-publication-treatments,
.pane-node-field-publication-dissorders {
  a {
    &:not(:last-child):after {
      content: ",";
      color: #000;

      &:hover {
        underline: none;
      }
    }
  }
}

.caption-inner {
  img {
    margin-bottom: 0 !important;
  }

  p {
    font-family: 'Open Sans';
    font-style: Italic;
    color: #777777;
    font-size: 13px;
    line-height: 18px;
    padding-bottom: 25px
  }
}

#edit-field-publication-treatments-target-id,
#edit-field-publication-dissorders-target-id,
#edit-submit-publications-papers,
label[for='edit-field-publication-treatments-target-id'],
label[for='edit-field-publication-dissorders-target-id'] {
  display: none;
}

#contentsb {
  .btn {
    margin-left: 20px;
  }
}

blockquote p {
  font-size: 18px;
  line-height: 1.77777;
}

.view-display-id-panel_pane_7 {
  display: block;
  float: left;
  width: 100%;

  @include breakpoint($md) {
    width: 64%;
    border-right: 1px solid #A8ADB3;
  }

  margin: 0px 0;

  .views-more-link {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.666666667;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 10px;
    color: #fff;
    background-color: #cf4520;
    border-color: #cf4520;
    margin: 5px 10px 0;

    @include breakpoint($md) {
      margin-left: 0px;
    }
  }

  h3 {
    margin: 0 10px;

    @include breakpoint($md) {
      margin-left: 0px;
    }

    margin-bottom: 30px;
    text-transform: capitalize;

  }

  p {
    margin: 0 10px;

    @include breakpoint($md) {
      margin-left: 0px;
    }
  }
}

.field-name-field-news-category {
  h3 {
    margin-top: 3px;
  }
}

#panels-ipe-paneid-587 {

  .panel-pane.pane-fieldable-panels-pane.pane-bundle-text {
    width: 350px;
    float: right;
  }
}

.panel-pane.pane-fieldable-panels-pane.pane-vuuid-931330e5-3c68-48fb-89a2-bd28b105e911.pane-bundle-text {
  width: 350px;
  float: right;
}

#donnews .pane-fieldable-panels-pane:first-child {
  border-right: 1px solid #A8ADB3;
  float: left;
}

#donnews .pane-fieldable-panels-pane {
  width: 350px;
  float: right;
}

#panels-ipe-paneid-588 {

  .panel-pane.pane-fieldable-panels-pane.pane-bundle-text {
    width: 350px;
    float: right;
  }
}


/* ------ Flex Slider ------- */
#thumbnails {
  width: 72%;
  margin: 0 auto 60px auto;
  cursor: pointer;
}

.flexslider {
  margin: 0;
  white-space: nowrap;

  &:hover .flex-direction-nav .flex-next,
  &:hover .flex-direction-nav .flex-prev {
    height: 50px;
    width: 25px;
  }

  .slides img:hover {
    opacity: 0.5;
  }
}
