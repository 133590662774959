@charset "UTF-8";
/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 16.6666%; }

#drawer-nav .level-2 {
  padding: 0 5px; }

.brand__lockup img {
  height: 95px; }

.btn--wcm {
  height: 42px;
  line-height: 31px; }

.section-inner {
  max-width: 1200px; }

footer {
  padding-top: 0; }

#banner {
  background: url("../images/background.jpg") right top/cover no-repeat;
  width: 100%; }
  @media screen and (min-width: 768px) {
    #banner {
      background: url("../images/background.jpg") center top/cover no-repeat; } }
  #banner .pane-bundle-text .field-item {
    overflow: hidden; }
  #banner .pane-bundle-text .fieldable-panels-pane {
    padding: 95px 0 15px 20px;
    margin-left: auto;
    margin-right: auto; }
    @media screen and (min-width: 768px) {
      #banner .pane-bundle-text .fieldable-panels-pane {
        margin-left: 345px; } }
    @media screen and (min-width: 992px) {
      #banner .pane-bundle-text .fieldable-panels-pane {
        margin-left: 440px;
        width: 515px; } }
    @media screen and (min-width: 1200px) {
      #banner .pane-bundle-text .fieldable-panels-pane {
        margin-left: 620px; } }
  #banner .pane-bundle-text p {
    display: block;
    color: #555555;
    margin: 0;
    font-size: 20px;
    line-height: 1.666;
    float: none;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5px; }
    @media screen and (min-width: 768px) {
      #banner .pane-bundle-text p {
        width: auto;
        max-width: 505px;
        clear: left;
        margin-left: 0; } }
  #banner .pane-bundle-text .btn--wcm {
    margin: 20px 20px 0 0;
    display: inline-block;
    background-color: transparent;
    color: #e7751d; }
    #banner .pane-bundle-text .btn--wcm:hover {
      color: #555555; }
    #banner .pane-bundle-text .btn--wcm:before {
      color: #555555; }
  #banner .pane-bundle-text p.nomar {
    max-width: none; }
    @media screen and (min-width: 992px) {
      #banner .pane-bundle-text p.nomar {
        margin: 0; } }
  #banner .pane-bundle-text .wcmc-button {
    margin-bottom: 20px;
    margin-top: 34px; }
    #banner .pane-bundle-text .wcmc-button:first-child {
      margin-top: 34px;
      margin-right: 34px; }
    @media screen and (min-width: 768px) {
      #banner .pane-bundle-text .wcmc-button {
        margin-bottom: 20px; } }

#last {
  width: 100%;
  min-height: 145px;
  background-color: #e7751d; }
  #last p {
    color: #ffffff;
    line-height: 27px;
    font-size: 18px;
    padding-top: 30px;
    margin-bottom: 0px; }
    #last p a {
      width: 100%;
      display: block;
      padding: 7px 0 0;
      color: #fff;
      font-size: 14px;
      text-decoration: underline; }
      @media screen and (min-width: 768px) {
        #last p a {
          display: inline;
          width: auto;
          padding: 15px 0 0 0;
          margin: 0 22px; } }
      #last p a:first-child {
        padding-right: 15px; }

#donnews .panel-separator {
  float: left;
  width: 0;
  display: none; }

#donnews .pane-fieldable-panels-pane {
  margin: 10px;
  float: left;
  min-height: 250px; }
  @media screen and (min-width: 992px) {
    #donnews .pane-fieldable-panels-pane {
      margin: 58px 0 80px; } }
  @media screen and (min-width: 992px) {
    #donnews .pane-fieldable-panels-pane {
      width: 100%; } }
  #donnews .pane-fieldable-panels-pane:first-child {
    padding-right: 25px;
    box-sizing: border-box; }
    @media screen and (min-width: 992px) {
      #donnews .pane-fieldable-panels-pane:first-child {
        width: 64.1%; } }
    @media screen and (min-width: 992px) {
      #donnews .pane-fieldable-panels-pane:first-child {
        border-right: 1px solid #A8ADB3; } }
  @media screen and (min-width: 992px) {
    #donnews .pane-fieldable-panels-pane {
      width: 35.65%; } }
  #donnews .pane-fieldable-panels-pane .wcmc-button {
    color: #807F80;
    border-color: #807F80; }
    #donnews .pane-fieldable-panels-pane .wcmc-button:hover {
      background-color: #807F80;
      color: #fff; }

#donnews p {
  line-height: 27px; }

#donnews img {
  float: left;
  margin-right: 10px;
  width: 49.5%; }
  @media screen and (min-width: 992px) {
    #donnews img {
      margin-right: 33px; } }

#donnews .pane-uuid-879229f0-7b39-40c6-951e-909b8fdd6662 p:first-child {
  margin-left: 0px; }

@media screen and (min-width: 992px) {
  #donnews .pane-uuid-4c5bde6b-4f20-4e59-8f05-4fddc3572b68 h3 {
    margin-left: 33px;
    margin-bottom: 12px; } }

#app {
  background-color: #cf4520;
  width: 100%;
  min-height: 250px;
  display: block; }
  #app .section-inner {
    margin: 0 auto;
    display: block;
    max-width: 100%; }
  @media screen and (min-width: 992px) {
    #app .fieldable-panels-pane {
      float: right;
      clear: left;
      width: 100%;
      overflow: hidden; } }
  #app h3.pane-title a {
    color: #fff; }
    #app h3.pane-title a:hover {
      text-decoration: underline; }
    #app h3.pane-title a:after {
      display: none; }
  @media screen and (min-width: 768px) {
    #app .pane-vuuid-0d786f6e-4b9f-4d85-95b8-39c09716794c div.fieldable-panels-pane {
      float: right;
      width: 600px;
      clear: both; } }
  #app .panel-pane {
    width: 100%;
    float: left; }
    @media screen and (min-width: 768px) {
      #app .panel-pane {
        width: 50%; } }
    #app .panel-pane .pane-content {
      max-width: 600px;
      float: none;
      display: block;
      clear: both;
      margin: 0 auto; }
      @media screen and (min-width: 992px) {
        #app .panel-pane .pane-content {
          float: right;
          clear: left;
          width: 600px; } }
    #app .panel-pane h3 {
      font-size: 30px;
      font-family: "Open-sans", sans-serif;
      text-transform: none;
      color: #fff;
      max-width: 600px;
      float: none;
      display: block;
      clear: both;
      margin: 16px auto;
      text-align: center;
      font-weight: normal; }
      @media screen and (min-width: 992px) {
        #app .panel-pane h3 {
          float: right;
          clear: left;
          margin: 16px 0;
          width: 600px; } }
    #app .panel-pane p {
      text-align: center;
      color: #fff; }
      @media screen and (min-width: 992px) {
        #app .panel-pane p {
          float: right;
          clear: left;
          width: 600px; } }
    #app .panel-pane a.tel {
      font-size: 30px;
      font-family: "Open-sans", sans-serif;
      text-transform: none;
      color: #fff;
      max-width: 600px;
      float: none;
      display: block;
      clear: both;
      margin: 0 auto; }
    #app .panel-pane a.btn--wcm {
      background-color: transparent;
      color: #e7751d; }
      #app .panel-pane a.btn--wcm:hover {
        border-color: #e7751d;
        color: #fff; }
  #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b {
    overflow: hidden;
    width: 100%; }
    @media screen and (min-width: 768px) {
      #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b {
        width: 50%; } }
    #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b .pane-content {
      height: 250px;
      width: 100%;
      max-width: none;
      float: left; }
      #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b .pane-content .fieldable-panels-pane {
        height: 100%; }
        #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b .pane-content .fieldable-panels-pane .field {
          height: 100%; }
          #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b .pane-content .fieldable-panels-pane .field .field-items {
            height: 100%; }
            #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b .pane-content .fieldable-panels-pane .field .field-items .field-item {
              height: 100%; }
    #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b p {
      width: 100%;
      height: 100%;
      margin: 0px; }
      #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b p a {
        width: 100%;
        min-height: 250px;
        font-size: 0px;
        text-indent: -9999px;
        display: block;
        background: url(../images/homemap.jpg) center center no-repeat;
        background-size: cover;
        filter: grayscale(100%);
        transition: all 1s; }
        #app .pane-vuuid-462342ef-4919-4042-941a-72483927363b p a:hover {
          -webkit-filter: grayscale(0);
          filter: grayscale(0);
          transform: scale(1.08, 1.08); }

#ctas h3.pane-title a {
  color: #fff; }
  #ctas h3.pane-title a:hover {
    text-decoration: underline; }
  #ctas h3.pane-title a:after {
    display: none; }

#ctas .pane-bundle-text:hover {
  background-color: #e87722; }
  #ctas .pane-bundle-text:hover .arrow-right {
    border-left: 17px solid #e87722;
    top: -63px; }
  #ctas .pane-bundle-text:hover a.btn {
    display: inline-block;
    background-color: #cf4520; }

#ctas .pane-bundle-text .arrow-right {
  display: none; }
  @media screen and (min-width: 992px) {
    #ctas .pane-bundle-text .arrow-right {
      display: block;
      position: relative;
      left: 320px;
      top: -62px;
      width: 0;
      height: 0;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent; } }

#ctas .panel-pane {
  width: 96%;
  margin: 5px 2% 5px;
  float: left;
  background-color: #999999;
  height: 144px;
  box-sizing: border-box; }
  @media screen and (min-width: 768px) {
    #ctas .panel-pane {
      margin: 25px 1px 38px;
      width: 32.9%; } }
  @media screen and (min-width: 992px) {
    #ctas .panel-pane {
      margin: 25px 2px 23px; } }
  #ctas .panel-pane h3 {
    font-size: 27px;
    font-family: "Open-sans", sans-serif;
    text-transform: none;
    color: #fff;
    text-align: center;
    font-weight: normal;
    margin: 13px 0 7px; }
  #ctas .panel-pane p {
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    margin: 0; }
  #ctas .panel-pane a.btn {
    float: none;
    margin-right: 0px;
    background-color: transparent;
    color: #fff;
    margin-top: 20px;
    border: none; }
    #ctas .panel-pane a.btn:hover {
      background-color: #cf4520; }

#ctas .pane-uuid-c81baede-f54f-4b64-86be-9c56631092ec {
  background-color: #666; }
  @media screen and (min-width: 992px) {
    #ctas .pane-uuid-c81baede-f54f-4b64-86be-9c56631092ec .arrow-right {
      border-left: 17px solid #666; } }
  @media screen and (min-width: 1200px) {
    #ctas .pane-uuid-c81baede-f54f-4b64-86be-9c56631092ec .arrow-right {
      left: 394px; } }

#ctas .pane-uuid-2fc81575-0747-4a09-be39-fd115e48e325 {
  background-color: #999999; }
  @media screen and (min-width: 992px) {
    #ctas .pane-uuid-2fc81575-0747-4a09-be39-fd115e48e325 .arrow-right {
      border-left: 17px solid #999999; } }
  @media screen and (min-width: 1200px) {
    #ctas .pane-uuid-2fc81575-0747-4a09-be39-fd115e48e325 .arrow-right {
      left: 395px; } }

#ctas .pane-uuid-bf6d82bc-48ac-46d6-98f4-4149725d47b3 {
  background-color: #B2B2B2; }
  @media screen and (min-width: 992px) {
    #ctas .pane-uuid-bf6d82bc-48ac-46d6-98f4-4149725d47b3 .arrow-right {
      border-left: 17px solid #B2B2B2; } }
  @media screen and (min-width: 1200px) {
    #ctas .pane-uuid-bf6d82bc-48ac-46d6-98f4-4149725d47b3 .arrow-right {
      left: 394px; } }

/* Bulk import */
.contact-info p {
  line-height: 22px; }

.contact-info a.map:before {
  content: '\e803';
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-right: 5px; }

.spiftv-links {
  padding-left: 0px;
  list-style: none;
  border-bottom: 1px solid #EFEFEF;
  margin-top: 0px; }
  .spiftv-links li {
    width: 100%;
    float: left; }
    .spiftv-links li a {
      float: left;
      display: inline-block;
      font-size: 16px;
      margin: 0 0 25px 0;
      height: 34px;
      line-height: 34px;
      position: relative;
      padding: 0 10px 0 5px;
      width: 128px; }
      .spiftv-links li a:hover {
        background-color: #3787b0;
        color: #ffffff;
        text-decoration: none; }
    .spiftv-links li a.wcmc-social-button {
      background-color: #3787b0;
      border-radius: 0px;
      width: 34px;
      font-size: 20px;
      box-sizing: border-box;
      padding-top: 3px;
      float: left;
      padding-left: 0;
      padding-right: 0; }

.item-list ul {
  padding: 0 !important; }

.node-type-profile .panel-hero-3col #related-content-sidebar {
  display: block; }
  @media screen and (min-width: 768px) {
    .node-type-profile .panel-hero-3col #related-content-sidebar {
      width: 420px;
      margin-left: 30px; } }
  @media screen and (min-width: 1200px) {
    .node-type-profile .panel-hero-3col #related-content-sidebar {
      width: 615px; } }
  .node-type-profile .panel-hero-3col #related-content-sidebar .field-label {
    float: none;
    display: inline;
    font-weight: bold; }
  .node-type-profile .panel-hero-3col #related-content-sidebar .field-items {
    float: none;
    display: inline; }
    .node-type-profile .panel-hero-3col #related-content-sidebar .field-items .field-item {
      float: none;
      display: inline; }

.panel-hero-3col #related-content-sidebar {
  width: 200px;
  margin-left: 27px;
  border-left: 1px solid #C7CBCE;
  padding-left: 1em; }
  .panel-hero-3col #related-content-sidebar .pane-title {
    text-transform: capitalize;
    letter-spacing: unset;
    font-size: 16px; }

.field-name-field-pops-profile-link {
  width: 100%;
  float: left; }
  .field-name-field-pops-profile-link a {
    float: left;
    padding: 0 13px;
    background-color: transparent;
    color: #807F80;
    border-radius: 0;
    border: 2px solid #807F80;
    text-transform: uppercase;
    width: auto;
    font-weight: normal;
    height: 33px;
    line-height: 33px;
    margin-top: 3px;
    margin-bottom: 32px;
    font-size: 13px; }
    .field-name-field-pops-profile-link a:hover {
      color: #ffffff;
      background-color: #807F80; }
    .field-name-field-pops-profile-link a:after {
      font-family: "SSStandard";
      content: '↪';
      font-size: 75%;
      vertical-align: top;
      text-rendering: optimizeLegibility;
      display: inline-block;
      text-decoration: none;
      -ms-font-feature-settings: "liga" 1;
      padding-left: 5px; }

.pane-node-field-biographical-info {
  float: left; }

.views-field-field-news-category a,
.field-name-field-news-category a {
  font-size: 14px;
  background: url(../images/tag.png) 5px 0 no-repeat;
  background-size: 16px 16px;
  padding-left: 30px; }

.views-field-field-news-category .views-label,
.views-field-field-news-category .field-label,
.field-name-field-news-category .views-label,
.field-name-field-news-category .field-label {
  font-weight: bold;
  float: left; }

label[for=edit-sort-by] {
  display: none; }

.view-teaser {
  border-bottom: none; }

#edit-sort-by {
  border-radius: 0;
  border: 1px solid #DBDCDD;
  font-size: 13px; }

figure.hero-image {
  margin-bottom: 4em; }

.sp_cta {
  padding: 30px 0; }
  .sp_cta .imgholder {
    overflow: hidden;
    float: left;
    height: auto;
    margin: 0 20px 19px 0; }
    @media screen and (min-width: 768px) {
      .sp_cta .imgholder {
        width: 45%; } }

.panel-hero-3col #main-content {
  padding-bottom: 25px; }

.view-display-id-panel_pane_1 .views-row-even {
  background-color: #F3F3F3; }

.view-display-id-panel_pane_1 h3 {
  color: #000;
  font-weight: bold;
  text-transform: capitalize;
  font-family: "Open-sans", sans-serif;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: 25px; }

.view-display-id-panel_pane_1 .views-field-biblio-date {
  font-size: 12px;
  padding-left: 19px;
  padding-right: 19px; }

.view-display-id-panel_pane_1 .views-field-biblio-authors {
  font-size: 12px;
  padding: 7px 19px; }

.view-display-id-panel_pane_1 .views-field-biblio-abst-e {
  padding-left: 19px;
  padding-right: 19px;
  padding-bottom: 25px; }

.pane-profiles-panel-pane-3,
.pane-profiles-panel-pane-6 {
  width: 100%;
  float: left; }
  .pane-profiles-panel-pane-3 .aboutprof,
  .pane-profiles-panel-pane-6 .aboutprof {
    width: 50%;
    float: left;
    margin: 25px 0;
    max-width: 240px; }
    @media screen and (min-width: 992px) {
      .pane-profiles-panel-pane-3 .aboutprof,
      .pane-profiles-panel-pane-6 .aboutprof {
        max-width: 100%; } }
  .pane-profiles-panel-pane-3 img,
  .pane-profiles-panel-pane-6 img {
    width: 95%;
    float: left; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 img,
      .pane-profiles-panel-pane-6 img {
        width: 32%; } }
  .pane-profiles-panel-pane-3 h4,
  .pane-profiles-panel-pane-3 p,
  .pane-profiles-panel-pane-3 a,
  .pane-profiles-panel-pane-6 h4,
  .pane-profiles-panel-pane-6 p,
  .pane-profiles-panel-pane-6 a {
    width: 100%;
    float: left; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 h4,
      .pane-profiles-panel-pane-3 p,
      .pane-profiles-panel-pane-3 a,
      .pane-profiles-panel-pane-6 h4,
      .pane-profiles-panel-pane-6 p,
      .pane-profiles-panel-pane-6 a {
        width: 68%; } }
  .pane-profiles-panel-pane-3 a,
  .pane-profiles-panel-pane-6 a {
    width: 100%;
    font-size: 14px;
    float: left;
    margin: 0; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 a,
      .pane-profiles-panel-pane-6 a {
        width: auto;
        padding-left: 19px; } }
  .pane-profiles-panel-pane-3 a.seeall,
  .pane-profiles-panel-pane-6 a.seeall {
    text-transform: uppercase;
    width: 100%;
    padding-left: 0; }
  .pane-profiles-panel-pane-3 h4,
  .pane-profiles-panel-pane-6 h4 {
    float: left;
    margin: 10px 0 0 0; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 h4,
      .pane-profiles-panel-pane-6 h4 {
        margin-top: 0px; } }
    .pane-profiles-panel-pane-3 h4 a,
    .pane-profiles-panel-pane-6 h4 a {
      font-size: 18px;
      max-width: 225px; }
  .pane-profiles-panel-pane-3 p,
  .pane-profiles-panel-pane-6 p {
    font-size: 14px;
    font-family: "Open-sans", sans-serif;
    font-style: italic;
    float: left;
    margin: 0; }
    @media screen and (min-width: 768px) {
      .pane-profiles-panel-pane-3 p,
      .pane-profiles-panel-pane-6 p {
        padding-left: 19px; } }

.pane-news-panel-pane-6 h4 {
  margin: 0; }

.pane-news-panel-pane-6 img {
  float: left;
  width: 320px;
  height: auto;
  border-radius: 0;
  padding-right: 19px; }

.pane-news-panel-pane-6 p.post-date {
  margin-bottom: 0px;
  margin-top: 0px; }

.pane-news-panel-pane-6 a.seeall {
  padding-bottom: 20px;
  float: left;
  width: 100%;
  text-transform: uppercase; }

.pane-node-body img {
  padding-right: 30px; }

.pane-node-body h2,
.pane-node-body h3 {
  margin-bottom: 15px; }

.pane-node-field-related-articles h3,
.pane-publications-papers-panel-pane-1 h3,
.pane-publications-papers-panel-pane-2 h3 {
  font-size: 16px;
  color: #4B525A;
  margin-left: 20px; }

#contentsb {
  width: 200px;
  margin-left: 27px;
  border-left: 1px solid #C7CBCE;
  float: right; }
  #contentsb ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    #contentsb ul li {
      padding-left: 1em;
      text-indent: -.7em;
      margin-left: 20px; }
      #contentsb ul li:before {
        content: "• ";
        color: #C9CACC;
        font-size: 16px; }
      #contentsb ul li div {
        display: inline; }
        #contentsb ul li div span {
          display: inline; }

.speechprof {
  float: left;
  margin: 25px 0;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .speechprof {
      width: 50%; } }
  .speechprof h4 {
    margin: 0 0 10px; }
  .speechprof img {
    float: left;
    margin-right: 19px;
    border: 1px solid #cccccc; }
  .speechprof span.summ {
    float: left;
    margin-top: 12.5px;
    margin-right: 10px; }

.pane-profiles-panel-pane-5 {
  margin-top: 30px;
  float: left;
  width: 100%; }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

/* Over writing button styles */
.btn--wcm {
  border-radius: 15px; }

.btn {
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 10px; }

.speechprof a.external-link,
.pane-node-field-pops-profile-link a.external-link {
  font-size: 14px;
  background: url("../images/sprites/clinprof.svg") 0 0 no-repeat;
  background-size: 32px 32px;
  padding-left: 42px;
  min-height: 32px;
  line-height: 16px;
  display: block; }
  @media screen and (min-width: 768px) {
    .speechprof a.external-link,
    .pane-node-field-pops-profile-link a.external-link {
      line-height: 32px; } }
  .speechprof a.external-link:after,
  .pane-node-field-pops-profile-link a.external-link:after {
    display: none; }

.speechprof a.external-link {
  float: left; }

a.external-link {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto; }

span.select2 {
  min-width: 200px; }

.view-id-News.view-display-id-panel_pane_5 .views-field-body {
  display: inline; }
  .view-id-News.view-display-id-panel_pane_5 .views-field-body .field-content {
    display: inline; }
    .view-id-News.view-display-id-panel_pane_5 .views-field-body .field-content p {
      display: inline; }

.view-id-News.view-display-id-panel_pane_5 .views-field-view-node {
  display: inline; }

.view-id-News.view-display-id-panel_pane_5 .views-field-field-news-category {
  margin-top: 10px; }

.view-display-id-panel_pane_5 .view-teaser {
  margin-bottom: 20px; }

.pane-views-exp-news-panel-pane-5 .form-item-sort-by {
  display: none; }

.pane-views-exp-news-panel-pane-5 label[for='edit-sort-order'] {
  display: none; }

.pane-views-exp-news-panel-pane-5 .views-widget-sort-order {
  float: none; }

.view-display-id-panel_pane_5 .field-content p:not(:first-child) {
  display: none; }

.view-display-id-panel_pane_5 .field-content a.moreless {
  padding-bottom: 5px; }

.pane-1 a.btn {
  margin-top: 10px; }

.pane-1 h3 {
  float: left;
  width: 100%; }

.btn-don {
  margin: 10px 10px 0 0;
  height: 86px;
  display: table-cell;
  line-height: 74px;
  float: left;
  width: 100%;
  margin-bottom: 20px;
  color: #cf4520;
  border-color: #cf4520;
  font-size: 15px;
  text-align: right; }
  @media screen and (min-width: 768px) {
    .btn-don {
      width: 50%;
      margin-bottom: 40px; } }
  .btn-don span {
    background-image: url("../../../../../profiles/wcmc/themes/wcmc_bootstrap/images/cta_icons/sprite/sprite.svg");
    background-position: -480px 0px;
    background-repeat: no-repeat;
    color: #555555;
    display: inline-block;
    font-size: 64px;
    height: 64px;
    margin: 5px 0 5px 55px;
    width: 64px;
    vertical-align: middle; }
    @media screen and (min-width: 768px) {
      .btn-don span {
        margin-left: 70px; } }
  .btn-don:hover {
    background-color: #fff;
    text-decoration: underline;
    color: #e7751d;
    border-color: #e7751d; }
  .btn-don:before {
    display: none; }

.btn-cont {
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
  height: 37px;
  line-height: 37px; }
  @media screen and (min-width: 768px) {
    .btn-cont {
      width: 48%;
      margin: 10px 0 0 0; } }

.views-field-field-publication-author {
  width: 100%;
  float: left; }

.views-label,
.field-label {
  font-weight: bold;
  float: left;
  padding-right: 5px; }

.pane-node-field-publication-treatments a:not(:last-child):after,
.pane-node-field-publication-dissorders a:not(:last-child):after {
  content: ",";
  color: #000; }
  .pane-node-field-publication-treatments a:not(:last-child):after:hover,
  .pane-node-field-publication-dissorders a:not(:last-child):after:hover {
    underline: none; }

.caption-inner img {
  margin-bottom: 0 !important; }

.caption-inner p {
  font-family: 'Open Sans';
  font-style: Italic;
  color: #777777;
  font-size: 13px;
  line-height: 18px;
  padding-bottom: 25px; }

#edit-field-publication-treatments-target-id,
#edit-field-publication-dissorders-target-id,
#edit-submit-publications-papers,
label[for='edit-field-publication-treatments-target-id'],
label[for='edit-field-publication-dissorders-target-id'] {
  display: none; }

#contentsb .btn {
  margin-left: 20px; }

blockquote p {
  font-size: 18px;
  line-height: 1.77777; }

.view-display-id-panel_pane_7 {
  display: block;
  float: left;
  width: 100%;
  margin: 0px 0; }
  @media screen and (min-width: 992px) {
    .view-display-id-panel_pane_7 {
      width: 64%;
      border-right: 1px solid #A8ADB3; } }
  .view-display-id-panel_pane_7 .views-more-link {
    display: inline-block;
    margin-bottom: 0;
    font-weight: normal;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border: 1px solid transparent;
    white-space: nowrap;
    padding: 6px 12px;
    font-size: 15px;
    line-height: 1.666666667;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 10px;
    color: #fff;
    background-color: #cf4520;
    border-color: #cf4520;
    margin: 5px 10px 0; }
    @media screen and (min-width: 992px) {
      .view-display-id-panel_pane_7 .views-more-link {
        margin-left: 0px; } }
  .view-display-id-panel_pane_7 h3 {
    margin: 0 10px;
    margin-bottom: 30px;
    text-transform: capitalize; }
    @media screen and (min-width: 992px) {
      .view-display-id-panel_pane_7 h3 {
        margin-left: 0px; } }
  .view-display-id-panel_pane_7 p {
    margin: 0 10px; }
    @media screen and (min-width: 992px) {
      .view-display-id-panel_pane_7 p {
        margin-left: 0px; } }

.field-name-field-news-category h3 {
  margin-top: 3px; }

#panels-ipe-paneid-587 .panel-pane.pane-fieldable-panels-pane.pane-bundle-text {
  width: 350px;
  float: right; }

.panel-pane.pane-fieldable-panels-pane.pane-vuuid-931330e5-3c68-48fb-89a2-bd28b105e911.pane-bundle-text {
  width: 350px;
  float: right; }

#donnews .pane-fieldable-panels-pane:first-child {
  border-right: 1px solid #A8ADB3;
  float: left; }

#donnews .pane-fieldable-panels-pane {
  width: 350px;
  float: right; }

#panels-ipe-paneid-588 .panel-pane.pane-fieldable-panels-pane.pane-bundle-text {
  width: 350px;
  float: right; }

/* ------ Flex Slider ------- */
#thumbnails {
  width: 72%;
  margin: 0 auto 60px auto;
  cursor: pointer; }

.flexslider {
  margin: 0;
  white-space: nowrap; }
  .flexslider:hover .flex-direction-nav .flex-next,
  .flexslider:hover .flex-direction-nav .flex-prev {
    height: 50px;
    width: 25px; }
  .flexslider .slides img:hover {
    opacity: 0.5; }

/* =Default Select Elements
----------------------------------------------------------*/
.form-select {
  max-width: none;
  height: 50px;
  border: 1px solid #dddddd;
  box-shadow: none;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M207 381.5L12.7 187.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 381.5c-9.4 9.3-24.6 9.3-34 0z' fill='%23cf4520'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 20px 20px;
  padding-right: 50px; }
